import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const AddProduct = () => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [img, setImg] = useState('');
  const [quantity, setQuantity] = useState('');
  const [products, setProducts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [images, setImages] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/products-get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleChooseImg = async () => {
    setDialogOpen(true);
    try {
      const response = await axios.get(`${apiUrl}/api/images`);
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleSelectImage = (selectedImg) => {
    setImg(selectedImg);
    setDialogOpen(false);
  };

  const handleUpload = async () => {
    try {
      await axios.post(`${apiUrl}/api/products`, { name, price, img, quantity });
      alert('Product added successfully');
      // Fetch the updated list of products after adding a new one
      const response = await axios.get(`${apiUrl}/api/products-get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleDelete = async (productId) => {
    try {
        await axios.delete(`${apiUrl}/api/products/${productId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        alert('Product deleted successfully');
        // Оновлення списку продуктів після видалення
        setProducts(products.filter(product => product._id !== productId));
    } catch (error) {
        console.error('Error deleting product:', error);
        alert('Failed to delete product');
    }
};


  return (
    <Box display="flex" justifyContent="space-between" p={3}>
      <Box flexBasis="40%">
        <Typography variant="h4" gutterBottom>Add New Product</Typography>
        <TextField fullWidth label="Product Name" value={name} onChange={(e) => setName(e.target.value)} margin="normal" />
        <TextField fullWidth label="Price" value={price} onChange={(e) => setPrice(e.target.value)} margin="normal" />
        <Box display="flex" alignItems="center">
          <TextField fullWidth label="Image URL" value={img} onChange={(e) => setImg(e.target.value)} margin="normal" />
          <Button variant="contained" color="secondary" onClick={handleChooseImg} style={{ marginLeft: '10px' }}>
            Choose Img
          </Button>
        </Box>
        <TextField fullWidth label="Quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} margin="normal" />
        <Button fullWidth variant="contained" color="primary" onClick={handleUpload} style={{ marginTop: '20px' }}>
          UPLOAD
        </Button>
      </Box>

      <Box flexBasis="55%">
        <Typography variant="h4" gutterBottom>Existing Products</Typography>
        <List style={{ maxHeight: 400, overflowY: 'auto' }}>
          {products.map(product => (
            <React.Fragment key={product._id}>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(product._id)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src={product.img} alt={product.name} />
                </ListItemAvatar>
                <ListItemText
                  primary={product.name}
                  secondary={`Price: $${product.price}, Quantity: ${product.quantity}`}
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Select an Image</DialogTitle>
        <DialogContent>
          <List>
            {images.map((img, index) => (
              <ListItem button onClick={() => handleSelectImage(img.url)} key={index}>
                <ListItemAvatar>
                  <Avatar src={img.url} alt={img.file} />
                </ListItemAvatar>
                <ListItemText primary={img.file} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddProduct;
