import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Grid, Card, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import FooterMenu from '../FooterMenu/FooterMenu';

const LevelCard = styled(Card)(({ theme, active }) => ({
  margin: theme.spacing(2, 0),
  backgroundColor: active ? theme.palette.primary.light : theme.palette.background.paper,
  boxShadow: active ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
  width: '100%',
}));

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: theme.spacing(2),
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'center',
  background: 'linear-gradient(90deg, rgba(29,78,216,1) 0%, rgba(2,132,199,1) 50%, rgba(56,189,248,1) 100%)',
  color: '#fff',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
}));

const BoldText = styled(Typography)({
  fontWeight: 'bold',
});

const RechargeCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: '100%',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const Recharge = () => {
  const [userData, setUserData] = useState({
    username: localStorage.getItem('username') || '',
    level: 0,
    commission: 0,
    dailyLimit: 0,
  });
  const [amount, setAmount] = useState('');
  const [chatLink, setChatLink] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const [data, setData] = useState(null);

  const calculateLevel = (accountSummary, levelRequirements) => {
    if (accountSummary >= levelRequirements.level3) {
      return 3;
    } else if (accountSummary >= levelRequirements.level2) {
      return 2;
    } else if (accountSummary >= levelRequirements.level1) {
      return 1;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(`${apiUrl}/api/account-summary`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        const dataResponse = await axios.get(`${apiUrl}/api/data`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        const userLevel = calculateLevel(userResponse.data.accountSummary, dataResponse.data.levelRequirements);
        setUserData({
          username: userResponse.data.username,
          level: userLevel,
          commission: dataResponse.data.commission[`level${userLevel}`],
          dailyLimit: dataResponse.data.dailyLimit[`level${userLevel}`],
        });
  
        setData(dataResponse.data);
      } catch (err) {
        console.error(err);
      }
    };
  
    const fetchChatLink = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/support-chat`);
        setChatLink(response.data.supportChat);
      } catch (error) {
        console.error('Error fetching support chat link:', error);
      }
    };
  
    fetchChatLink();
    fetchData();
  }, []);
  

  const handleRechargeConfirm = () => {
    if (parseFloat(amount) >= 100) {
      if (chatLink) {
        // Додаємо суму поповнення до посилання
        const whatsappLink = `${chatLink}&text=I%20want%20to%20recharge%20${amount}%20USDT`;
        window.location.href = whatsappLink;
      } else {
        alert('WhatsApp chat link is not available.');
      }
    } else {
      alert('Minimum recharge amount is 100 USDT');
    }
  };
  

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <Container>
      {/* Форма поповнення у контейнері з тінями */}
      <RechargeCard>
        <CardContent>
          <Box p={3}>
            <Typography variant="h5" align="center" gutterBottom>Recharge</Typography>
            <Typography variant="h6" align="center" color="textSecondary" gutterBottom>USDT - TRC20</Typography>
            <Box mt={3}>
              <Typography variant="body1" align="center" gutterBottom>Enter Recharge Amount</Typography>
              <Grid container spacing={2} justifyContent="center">
                {['100', '200', '300', '500', '800', '1000', '2000', '4000'].map((amt) => (
                  <Grid item key={amt}>
                    <Button variant="outlined" onClick={() => setAmount(amt)}>
                      USDT {amt}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <Box mt={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={handleAmountChange}
                />
              </Box>
            </Box>
            <Box mt={4} display="flex" justifyContent="center">
              <Button variant="contained" color="primary" onClick={handleRechargeConfirm} style={{ width: '100%' }}>
                Confirm Recharge
              </Button>
            </Box>
          </Box>
        </CardContent>
      </RechargeCard>

      {/* Інформаційний блок */}
      <Box display="flex" flexDirection="column" alignItems="center" mb={4} width="100%">
        <Typography variant="h6">{userData.username}</Typography>
        <HeaderBox>
          <Box textAlign="center" mx={1}>
            <BoldText variant="subtitle2">LV.{userData.level}</BoldText>
            <BoldText variant="body2">Level</BoldText>
          </Box>
          <Box textAlign="center" mx={1}>
            <BoldText variant="subtitle2">{userData.dailyLimit}</BoldText>
            <BoldText variant="body2">Daily order limit</BoldText>
          </Box>
          <Box textAlign="center" mx={1}>
            <BoldText variant="subtitle2">{userData.commission}%</BoldText>
            <BoldText variant="body2">Commission</BoldText>
          </Box>
        </HeaderBox>
      </Box>

      {data && Object.entries(data.levelRequirements).map(([key, value], index) => (
        <LevelCard key={index} active={index === userData.level}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h6" color={index === userData.level ? 'primary' : 'textPrimary'}>
                  LV.{index}
                </Typography>
                <Typography variant="body2">Min Balance: {value} $</Typography>
              </Box>
            </Box>
            <Divider style={{ margin: '10px 0' }} />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">Level: {index}</Typography>
              <Typography variant="body2">Daily order limit: {data.dailyLimit[`level${index}`]}</Typography>
              <Typography variant="body2">Commission: {data.commission[`level${index}`]}%</Typography>
            </Box>
          </CardContent>
        </LevelCard>
      ))}

      <FooterMenu />
    </Container>
  );
};

export default Recharge;
