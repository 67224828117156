import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddTaskIcon from '@mui/icons-material/AddTask';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FooterMenu from '../FooterMenu/FooterMenu';
import axios from 'axios';
import Marquee from 'react-fast-marquee';

const Home = () => {
  const navigate = useNavigate();
  const [chatLink, setChatLink] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [marqueeData, setMarqueeData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const logoutUser = () => {
    localStorage.clear() 
    navigate('/login');
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setPhoneNumber(response.data.phoneNumber || '');
      } catch (err) {
        logoutUser();
        console.error(err);
      }
    };
    

    const fetchChatLink = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/support-chat`);
        setChatLink(response.data.supportChat);
      } catch (error) {
        console.error('Error fetching support chat link:', error);
      }
    };

    fetchUserData();
    fetchChatLink();
    generateMarqueeData();
  }, []);

  const generateMarqueeData = () => {
    const data = [];
    for (let i = 0; i < 60; i++) {
      const user = `user${Math.floor(100000 + Math.random() * 900000)}`;
      const amount = `$${Math.floor(1000 + Math.random() * 8000)}`;
      data.push(`${user} earned ${amount}`);
    }
    setMarqueeData(data);
  };

  const handleSupportClick = () => {
    if (chatLink) {
      window.open(chatLink, '_blank');
    } else {
      navigate('/support');
    }
  };

  return (
    <div className="home-container">
     <div className='marquee'>
      <Marquee gradient={false} speed={40}>
        <Typography variant="h6" component="div" className="marquee-text">
          {marqueeData.join(' • ')}
        </Typography>
      </Marquee>
      </div>
      <div className="menu">
        <h1>Welcome, {phoneNumber}!</h1>
      </div>
      <div className="menu">
        <div className="menu-item" onClick={() => navigate('/recharge')}>
          <AccountBalanceWalletIcon style={{ fontSize: 50 }} />
          <span>Top-Up</span>
        </div>
        <div className="menu-item" onClick={() => navigate('/withdraw')}>
          <AttachMoneyIcon style={{ fontSize: 50 }} />
          <span>Withdraw</span>
        </div>
        <div className="menu-item" onClick={() => navigate('/start-task')}>
          <AddTaskIcon style={{ fontSize: 50 }} />
          <span>New Task</span>
        </div>
        <div className="menu-item" onClick={handleSupportClick}>
          <SupportAgentIcon style={{ fontSize: 50 }} />
          <span>Support</span>
        </div>
      </div>
      <div className="banner-container">
        <img src="/amazon-banner.png" alt="Amazon Banner" className="banner-image" />
        <img src="/google-banner.png" alt="Google Banner" className="banner-image" />
        <img src="/shopify-banner.png" alt="Shopify Banner" className="banner-image" />
        <img src="/marketplace-banner.png" alt="Marketplace Banner" className="banner-image" />
      </div>

      <FooterMenu />
    </div>
  );
};

export default Home;
