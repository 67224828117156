import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './VerifyOTP.css';

const VerifyOTP = ({ registrationData }) => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Використовуємо confirmationResult для верифікації OTP
      const result = await registrationData.confirmationResult.confirm(otp);
      console.log('OTP verified successfully:', result.user);

      // Надсилаємо дані для реєстрації на сервер
      await axios.post(`${apiUrl}/api/register`, {
        username: registrationData.username,
        password: registrationData.password,
        phoneNumber: registrationData.phoneNumber
      });

      navigate('/login');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('Invalid OTP or unexpected error occurred');
      }
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <div className="form-wrapper">
      <h2 className="title">Verify OTP</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className="input"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          required
        />
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="button">Verify OTP</button>
      </form>
    </div>
  );
};

export default VerifyOTP;
