import React, { useEffect, useState } from 'react';
import './Profile.css';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import FooterMenu from '../FooterMenu/FooterMenu';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Profile = () => {
  const [userData, setUserData] = useState({
    phoneNumber: '',
    accountSummary: 0,
    level: 0,
    commission: 0,
    totalProfit: 0,
    avatar: '',
  });
  const [levelRequirements, setLevelRequirements] = useState({});
  const [chatLink, setChatLink] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get(`${apiUrl}/api/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        const dataResponse = await axios.get(`${apiUrl}/api/data`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        const response = await axios.get(`${apiUrl}/api/support-chat`);
        setChatLink(response.data.supportChat);

        const user = userResponse.data;
        const requirements = dataResponse.data.levelRequirements;
        const commission = dataResponse.data.commission[`level${user.level}`];

        console.log('Fetched user data:', user);
        console.log('User account summary:', user.accountSummary);

        setUserData({ ...user, commission });
        setLevelRequirements(requirements);
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };

    const intervalId = setInterval(fetchUserData, 1000);

    return () => clearInterval(intervalId); // Очищення інтервалу при розмонтуванні компонента
  }, [apiUrl]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('avatar');
    navigate('/login');
  };

  const handleEditProfile = () => {
    navigate('/edit-profile');
  };

  const handleOnlineSupport = () => {
    if (chatLink) {
      window.open(chatLink, '_blank');
    } else {
      navigate('/support');
    }
  };

  const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    cursor: 'pointer',
  };

  const iconStyle = {
    marginRight: '10px',
  };

  const { phoneNumber, accountSummary, level, commission, avatar, totalProfit } = userData;

  // Визначення прогресу і наступної суми досягнення рівня
  const currentLevelMax = levelRequirements[`level${level}`] || 0;
  const nextLevelMax = levelRequirements[`level${level + 1}`] || 0;

  // Прогрес бар і сума досягнення наступного рівня
  const progress = level < 3 ? (accountSummary / nextLevelMax) * 100 : 100;
  const nextLevelAmount = level < 3 ? Math.max(nextLevelMax - accountSummary, 0) : 0;

  return (
    <div className="profile-container">
      <div className="profile-header">
        {avatar ? (
          <img src={avatar} alt="Avatar" className="profile-avatar" />
        ) : (
          <AccountCircleIcon style={{ fontSize: 100, marginBottom: 10 }} />
        )}
        <h2 className="profile-username">{phoneNumber}</h2>
        <Typography variant="h6">Level {level}</Typography>
        <Box width="100%" mt={2}>
          <LinearProgress variant="determinate" value={progress} style={{ height: '10px' }} />
        </Box>
        <Typography variant="body2" color="textSecondary" className="progress-text" style={{ marginTop: '10px' }}>
          Commission: {commission}%
        </Typography>
        {level < 3 && (
          <Typography variant="body2" color="textSecondary" className="progress-text">
            To next level: ${nextLevelAmount.toFixed(2)}
          </Typography>
        )}
      </div>
  
      <div className="profile-balance">
        <div className="balance-item">
          <span className="balance-amount">{accountSummary.toFixed(2)}</span>
          <span className="balance-label">Account balance</span>
        </div>
        <div className="balance-item">
          <span className="balance-amount">{totalProfit.toFixed(2)}</span>
          <span className="balance-label">All time profit</span>
        </div>
      </div>
  
      <div className="menu-container">
        <div className="profile-menu">
          <div style={menuItemStyle} onClick={() => navigate('/recharge')}>
            <AccountBalanceWalletIcon style={iconStyle} />
            <span>Recharge</span>
          </div>
          <div style={menuItemStyle} onClick={() => navigate('/withdraw')}>
            <AttachMoneyIcon style={iconStyle} />
            <span>Withdraw</span>
          </div>
          <div style={menuItemStyle} onClick={() => navigate('/withdraw')}>
            <HistoryIcon style={iconStyle} />
            <span>Payment Setting</span>
          </div>
          <div style={menuItemStyle} onClick={handleOnlineSupport}>
            <SupportAgentIcon style={iconStyle} />
            <span>Online Support</span>
          </div>
          <div style={menuItemStyle} onClick={handleEditProfile}>
            <EditIcon style={iconStyle} />
            <span>Edit Profile</span>
          </div>
          <div style={menuItemStyle} onClick={() => navigate('/change-password')}>
            <LockResetIcon style={iconStyle} />
            <span>Change Password</span>
          </div>
          <div className="logout-style" onClick={handleLogout}>
            <LogoutIcon style={iconStyle} />
            <span>Logout</span>
          </div>
        </div>
      </div>
  
      <FooterMenu />
    </div>
  );
};

export default Profile;
