import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import axios from 'axios';

const DataSettings = () => {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false); // Додаємо стан для контролю завантаження даних
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchData = async () => {
    try {
      setIsFetching(true); // Показуємо, що відбувається завантаження
      const response = await axios.get(`${apiUrl}/api/data`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setData(response.data);
      setIsFetching(false); // Завершуємо процес завантаження
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData(); // Завантаження даних при завантаженні компонента
  }, []);

  const handleDataChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value
    }));
  };

  const handleObjectChange = (parentField, field, value) => {
    setData((prevData) => ({
      ...prevData,
      [parentField]: {
        ...(typeof prevData[parentField] === 'object' && prevData[parentField] !== null ? prevData[parentField] : {}),
        [field]: value
      }
    }));
  };

  const handleArrayChange = (parentField, level, index, value) => {
    setData((prevData) => {
      if (!prevData[parentField] || !Array.isArray(prevData[parentField][`level${level}`])) {
        console.error('Invalid level or parentField');
        return prevData;
      }

      const updatedArray = [...prevData[parentField][`level${level}`]];
      updatedArray[index] = isNaN(value) ? 0 : parseInt(value);

      return {
        ...prevData,
        [parentField]: {
          ...prevData[parentField],
          [`level${level}`]: updatedArray
        }
      };
    })
  };

  const handleDataSave = async () => {
    try {
      await axios.post(`${apiUrl}/api/data`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      alert('Data updated successfully');
      // Виклик fetchData після збереження, щоб переконатися, що стан і сервер синхронізовані
      fetchData();
    } catch (error) {
      console.error('Error updating data:', error);
      alert('Failed to update data');
    }
  };

  return (
    <Box component={Paper} p={2} mt={2} sx={{ width: '100%' }}>
      <Typography variant="h4" gutterBottom>General Data</Typography>
      {isFetching ? (
        <Typography>Loading data...</Typography>
      ) : data ? (
        <Box>
          <Grid container spacing={2}>
            {Object.keys(data.priceRange).map((level, index) => (
              <Grid item xs={12} sm={6} md={3} key={level}>
                <Typography variant="h6">{`Level ${index}`}</Typography>
                <TextField
                  fullWidth
                  label={`Min (Level ${index})`}
                  type="number"
                  value={data.priceRange[level][0]}
                  onChange={(e) => handleArrayChange('priceRange', index, 0, e.target.value)}
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  label={`Max (Level ${index})`}
                  type="number"
                  value={data.priceRange[level][1]}
                  onChange={(e) => handleArrayChange('priceRange', index, 1, e.target.value)}
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} mt={2}>
            {Object.keys(data.commission).map((level, index) => (
              <Grid item xs={12} sm={6} md={3} key={level}>
                <Typography variant="h6">Commission</Typography>
                <TextField
                  fullWidth
                  label={`Commission (Level ${index})`}
                  type="number"
                  value={data.commission[level]}
                  onChange={(e) => handleObjectChange('commission', level, parseFloat(e.target.value))}
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} mt={2}>
            {Object.keys(data.dailyLimit).map((level, index) => (
              <Grid item xs={12} sm={6} md={3} key={level}>
                <Typography variant="h6">Daily Limit</Typography>
                <TextField
                  fullWidth
                  label={`Daily Limit (Level ${index})`}
                  type="number"
                  value={data.dailyLimit[level]}
                  onChange={(e) => handleObjectChange('dailyLimit', level, parseInt(e.target.value))}
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} mt={2}>
            {Object.keys(data.levelRequirements).map((level, index) => (
              <Grid item xs={12} sm={6} md={3} key={level}>
                <Typography variant="h6">Level Requirements</Typography>
                <TextField
                  fullWidth
                  label={`Level Requirements (Level ${index})`}
                  type="number"
                  value={data.levelRequirements[level]}
                  onChange={(e) => handleObjectChange('levelRequirements', level, parseInt(e.target.value))}
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Special Task Threshold"
                type="number"
                value={data.specialTaskThreshold}
                onChange={(e) => handleDataChange('specialTaskThreshold', parseInt(e.target.value))}
                margin="dense"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Special Task Penalty Percent"
                type="number"
                value={data.specialTaskPenaltyPercent}
                onChange={(e) => handleDataChange('specialTaskPenaltyPercent', parseInt(e.target.value))}
                margin="dense"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Support Chat"
                value={data.supportChat}
                onChange={(e) => handleDataChange('supportChat', e.target.value)}
                margin="dense"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Button variant="contained" color="primary" onClick={handleDataSave} sx={{ marginTop: 2 }}>
            Save
          </Button>
        </Box>
      ) : (
        <Typography>Loading data...</Typography>
      )}
    </Box>
  );
};

export default DataSettings;
