import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, Box } from '@mui/material';
import axios from 'axios';
import './EditProfile.css';
import FooterMenu from '../FooterMenu/FooterMenu';

const EditProfile = () => {
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [avatar, setAvatar] = useState(localStorage.getItem('avatar') || '');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAvatar(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${apiUrl}/api/edit-profile`, 
        { username, avatar },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      localStorage.setItem('username', response.data.username);
      localStorage.setItem('avatar', response.data.avatar);
      navigate('/profile');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <Box className="edit-profile-container">
      <Typography variant="h5" align="center">Edit Profile</Typography>
      <Box mt={2}>
        <TextField
          variant="outlined"
          fullWidth
          label="Username"
          value={username}
          onChange={handleUsernameChange}
        />
      </Box>
      <Box mt={2} className="avatar-section">
        <Button variant="contained" component="label">
          Upload Avatar
          <input type="file" accept="image/*" hidden onChange={handleAvatarChange} />
        </Button>
        {avatar && <img src={avatar} alt="Avatar" className="avatar-preview" />}
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
      <FooterMenu />
    </Box>
  );
};

export default EditProfile;
