import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const Sidebar = ({ onSelectMenu }) => (
  <div style={{ width: 250, backgroundColor: '#333', height: '100vh', color: 'white' }}>
    <List>
      <ListItem button onClick={() => onSelectMenu('dashboard')}>
        <ListItemIcon style={{ color: 'white' }}><DashboardIcon /></ListItemIcon>
        <ListItemText primary="Dashboard" style={{ color: 'white' }} />
      </ListItem>
      <ListItem button onClick={() => onSelectMenu('users')}>
        <ListItemIcon style={{ color: 'white' }}><PeopleIcon /></ListItemIcon>
        <ListItemText primary="Users" style={{ color: 'white' }} />
      </ListItem>
      <ListItem button onClick={() => onSelectMenu('data')}>
        <ListItemIcon style={{ color: 'white' }}><SettingsIcon /></ListItemIcon>
        <ListItemText primary="General Data" style={{ color: 'white' }} />
      </ListItem>
      <ListItem button onClick={() => onSelectMenu('tasks')}>
        <ListItemIcon style={{ color: 'white' }}><ListAltIcon /></ListItemIcon>
        <ListItemText primary="Tasks" style={{ color: 'white' }} />
      </ListItem>
      <ListItem button onClick={() => onSelectMenu('payments')}>
        <ListItemIcon style={{ color: 'white' }}><PaymentIcon /></ListItemIcon>
        <ListItemText primary="Payments" style={{ color: 'white' }} />
      </ListItem>
    </List>
  </div>
);

export default Sidebar;
