import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Login.css';

const Login = ({ setToken, setUsername }) => {
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedPhoneNumber = `+${phoneNumber.replace(/\D/g, '')}`;
      const response = await axios.post(`${apiUrl}/api/login/`, {
        phoneNumber: formattedPhoneNumber,
        password,
      });
      const { token, username, isAdmin } = response.data;
      setToken(token);
      setUsername(username);
      localStorage.setItem('token', token); // Збереження токена в локальному сховищі
      localStorage.setItem('username', username); // Збереження нікнейму в локальному сховищі

      if (isAdmin) {
        navigate('/admin');
      } else {
        navigate('/home');
      }

    } catch (error) {
      console.error('Login failed:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  return (
    <div className="form-wrapper">
      <h2 className="title">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <PhoneInput
            country={'us'}
            value={phoneNumber}
            onChange={setPhoneNumber}
            containerClass="phone-input-container"
            inputClass="phone-input"
          />
          <input
            type="password"
            className="input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="button">Login</button>
      </form>
      <p className="link-text">Not registered yet? <Link to="/register">Register</Link></p>
    </div>
  );
};

export default Login;
