import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Button, Modal, Rating, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

const TaskCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  background: 'linear-gradient(to right, #e3f2fd, #bbdefb)',
  padding: theme.spacing(2),
  borderRadius: '8px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  outline: 'none',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: theme.spacing(2),
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
}));

const NewTask = ({ open, onClose }) => {
  const [task, setTask] = useState(null);
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [userCommission, setUserCommission] = useState(0);
  const [accountSummary, setAccountSummary] = useState(0);
  const [isFreeze, setIsFreeze] = useState(false);
  const [allTasksCompleted, setAllTasksCompleted] = useState(false);
  const [insufficientFundsMessage, setInsufficientFundsMessage] = useState('');
  const [isSpecialTask, setIsSpecialTask] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTask = async () => {
      if (!open) return;
  
      try {
        setLoading(true);
  
        const userResponse = await axios.get(`${apiUrl}/api/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        const user = userResponse.data;
        setIsFreeze(user.isFreeze);
        setUserCommission(user.commission);
        setAccountSummary(user.accountSummary);
        setAllTasksCompleted(user.allTasksCompleted);
  
        console.log('User data:', user);
  
        // Перевірка, чи виконано всі завдання
        if (user.allTasksCompleted) {
          setAllTasksCompleted(true);
          return;
        }
  
        // Запит на завдання
        const taskResponse = await axios.get(`${apiUrl}/api/tasks`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        // Перевірка, чи це спеціальне завдання
        if (taskResponse.data && taskResponse.data.taskData) {
          setTask(taskResponse.data.taskData);
          setIsSpecialTask(true);
        } else {
          setTask(taskResponse.data);
          setIsSpecialTask(false);
        }
  
      } catch (error) {
        setModalMessage('Error loading task. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchTask();
  }, [open]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get(`${apiUrl}/api/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        const user = userResponse.data;
        setIsFreeze(user.isFreeze);
        setAllTasksCompleted(user.allTasksCompleted);
        setAccountSummary(user.accountSummary); // Оновлюємо accountSummary
        setUserCommission(user.commission); // Оновлюємо userCommission
  
        if (user.isFreeze) {
          setModalMessage('Your account is currently frozen. Please contact support to resolve the issue and unlock your account.');
        }
      } catch (error) {
        console.error('Error checking user status:', error);
      }
    };
  
    fetchUserData(); // Викликаємо fetchUserData один раз при завантаженні компонента
  
    const interval = setInterval(fetchUserData, 2000); // Оновлюємо дані кожні 2 секунди
  
    return () => clearInterval(interval); // Очищення інтервалу при демонтажі компонента
  
  }, []); // Порожній масив залежностей, щоб виконати тільки один раз при монтуванні
  
  
  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        // Оновлюємо стан завдань один раз при завантаженні компонента
        const taskResponse = await axios.get(`${apiUrl}/api/tasks`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        if (taskResponse.data && taskResponse.data.taskData) {
          setTask(taskResponse.data.taskData);
          setIsSpecialTask(true);
        } else {
          setTask(taskResponse.data);
          setIsSpecialTask(false);
        }
  
      } catch (error) {
        console.error('Error fetching task:', error);
      }
    };
  
    fetchTaskData(); // Викликаємо fetchTaskData один раз при завантаженні компонента
  
  }, []); // Порожній масив залежностей, щоб виконати тільки один раз при монтуванні
  
  
  

  const handleSubmit = async () => {
    if (isFreeze) {
      setModalMessage('Your account is currently frozen. Please contact support to resolve the issue and unlock your account.');
      return;
    }
    if (task.price > accountSummary) {
      setInsufficientFundsMessage('You do not have enough funds to complete this task.');
      return;
    }
  
    try {
      setLoading(true);
  
      const apiPostUrl = isSpecialTask ? `${apiUrl}/api/special-task-complete` : `${apiUrl}/api/task-complete`;
      const response = await axios.post(apiPostUrl, {
        taskId: task._id,
        rating,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      const updatedUser = response.data.user;
      setAccountSummary(updatedUser.accountSummary);
      setIsFreeze(updatedUser.isFreeze);
      setAllTasksCompleted(updatedUser.allTasksCompleted);
  
      if (updatedUser.isFreeze) {
        setModalMessage('Your account is currently frozen. Please contact support to resolve the issue and unlock your account.');
      } else if (updatedUser.allTasksCompleted) {
        setModalMessage('All tasks for today have been completed!');
      } else {
        // Повторний запит завдання після виконання поточного
        const taskResponse = await axios.get(`${apiUrl}/api/tasks`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (taskResponse.data && taskResponse.data.taskData) {
          setTask(taskResponse.data.taskData);
          setIsSpecialTask(true);
        } else {
          setTask(taskResponse.data);
          setIsSpecialTask(false);
        }
      }

      setRating(0)
      setLoading(false);
    } catch (error) {
      console.error('Error submitting task:', error);
      setLoading(false);
    }
  };
  
  const calculateReward = (price, isSpecialTask) => {
    const commission = isSpecialTask ? 10 : userCommission; // 10% комісія для спеціальних завдань
    const reward = (price && commission) ? price * (commission / 100) : 0;
    return reward;
  };

  return (
    <>
      <Modal
        open={open || allTasksCompleted}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContent onClick={onClose}>
          {loading ? (
            <LoadingContainer onClick={(e) => e.stopPropagation()}>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <>
              {allTasksCompleted ? (
                <Typography variant="h5" component="h2">
                  You have reached your daily task limit
                </Typography>
              ) : (
                task && (
                  <TaskCard onClick={(e) => e.stopPropagation()}>
                    <CardContent>
                      <img src={task.img && task.img.startsWith('http') ? task.img : `${apiUrl}${task.img}`} alt={task.name} style={{ width: '100%', maxHeight: '200px', objectFit: 'contain', marginBottom: '16px' }} />
                      <Typography variant="h5" component="h2" gutterBottom>{task.name}</Typography>
                      <Typography variant="h6" component="p" style={{ fontWeight: 'bold' }}>
                        ${task.price.toFixed(2)}
                      </Typography>
                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        Quantity: {task.quantity}
                      </Typography>
                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        Reward: ${calculateReward(task.price, isSpecialTask).toFixed(2)}
                      </Typography>
                      <Box my={2}>
                        <Rating
                          name="task-rating"
                          value={rating}
                          onChange={(event, newValue) => setRating(newValue)}
                          size="large"
                        />
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        fullWidth
                      >
                        GRAB ORDER
                      </Button>
                      {insufficientFundsMessage && (
                        <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
                          {insufficientFundsMessage}
                        </Typography>
                      )}
                    </CardContent>
                  </TaskCard>
                )
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewTask;
