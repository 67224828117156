import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import axios from 'axios';
import Sidebar from './Sidebar/Sidebar';
import DataSettings from './DataSettings';
import AddProduct from './AddProduct';
import { useNavigate } from 'react-router-dom';
import './AdminPanelBasic.css'; // Підключаємо CSS файл

const AdminPanelBasic = () => {
  const [selectedMenu, setSelectedMenu] = useState('dashboard');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [resetTaskCount, setResetTaskCount] = useState(0);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    phoneNumber: '',
    isAdmin: false,
    isFreeze: false,
    specialTask: false,
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); // Використання useNavigate

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUsers(response.data);
        setFilteredUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (selectedMenu === 'users') {
      fetchUsers();
    }
  }, [selectedMenu]);

  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setResetTaskCount(user.completedTasksToday);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  const handleOpenCreateUserModal = () => {
    setOpenCreateUserModal(true);
  };

  const handleCloseCreateUserModal = () => {
    setOpenCreateUserModal(false);
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredUsers.length / usersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );

  const handleCreateUser = async () => {
    try {
      await axios.post(`${apiUrl}/api/create-user`, newUser, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setOpenCreateUserModal(false);
      setNewUser({
        username: '',
        password: '',
        phoneNumber: '',
        isAdmin: false,
        isFreeze: false,
        specialTask: false,
      });
      alert('User created successfully');

      const response = await axios.get(`${apiUrl}/api/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error('Error creating user:', error);
      alert('Error creating user');
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await axios.delete(`${apiUrl}/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        alert('User deleted successfully');

        // Оновлення списку користувачів після видалення
        const response = await axios.get(`${apiUrl}/api/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setUsers(response.data);
        setOpenModal(false);
        setFilteredUsers(response.data);

      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Error deleting user');
      }
    }
  };

  const handleSaveChanges = async () => {
    try {
      if (selectedUser.limitTask === 0) {
        selectedUser.limitTask = null;
      }

      await axios.put(`${apiUrl}/api/users/${selectedUser._id}`, selectedUser, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setOpenModal(false);
      setSelectedUser(null);
      alert('User data updated successfully');
      console.log(selectedUser.completedTasksToday);

      const response = await axios.get(`${apiUrl}/api/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Error updating user data');
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = users.filter((user) =>
      user.phoneNumber.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };

  return (
    <Box display="flex" width="100vw">
      <Sidebar onSelectMenu={setSelectedMenu} />
      <Box flexGrow={1} p={3} sx={{ width: '100%' }}>
        {selectedMenu === 'dashboard' && (
          <Box>
            <Typography variant="h4">Dashboard</Typography>
          </Box>
        )}
        {selectedMenu === 'users' && (
          <Box width="100%">
            <Box className="header-bar">
              <Typography className="header-title">VIEW USERS</Typography>
              <Box>
                <Button
                  className="add-user-button"
                  variant="contained"
                  onClick={handleOpenCreateUserModal}
                  style={{ marginRight: '10px' }}
                >
                  + ADD USER
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => window.open('/profile', '_blank')}
                >
                  Go to Profile
                </Button>
              </Box>
            </Box>
            <Box className="filter-bar">
              <TextField
                className="search-input"
                margin="dense"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ width: '150px' }} // Зменшення ширини кнопки пошуку
              />
            </Box>
            <Box className="user-table-container">
              <Box className="user-table">
                <Box className="user-table-header">
                  <Box className="user-table-cell">#</Box>
                  <Box className="user-table-cell">Name</Box>
                  <Box className="user-table-cell">Phone</Box>
                  <Box className="user-table-cell">Completed Tasks Today</Box>
                  <Box className="user-table-cell">Special Task</Box>
                  <Box className="user-table-cell">Status</Box>
                  <Box className="user-table-cell">Actions</Box>
                </Box>
                {paginatedUsers.map((user, index) => (
                  <Box className="user-table-row" key={user._id}>
                    <Box className="user-table-cell">
                      {(currentPage - 1) * usersPerPage + index + 1}
                    </Box>
                    <Box className="user-table-cell">{user.username}</Box>
                    <Box className="user-table-cell">{user.phoneNumber}</Box>
                    <Box className="user-table-cell">
                      {user.completedTasksToday}
                    </Box>
                    <Box className="user-table-cell">
                      <td>
                        <span
                          className={`status-button ${user.specialTask || user.completedTasksToday === user.limitTask
                              ? 'status-got'
                              : 'status-not-received'
                            }`}
                        >
                          {user.specialTask || user.completedTasksToday === user.limitTask
                            ? 'Got'
                            : 'Did not receive'}
                        </span>
                      </td>
                    </Box>

                    <Box className="user-table-cell">
                      {user.isFreeze ? 'Frozen' : 'Active'}
                    </Box>
                    <Box className="user-table-cell">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenModal(user)}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className="pagination-buttons" sx={{ textAlign: 'center', marginTop: '20px' }}>
              <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}
              >
                Next
              </Button>
            </Box>
          </Box>
        )}
        {selectedMenu === 'data' && <DataSettings />}
        {selectedMenu === 'payments' && (
          <Box>
            <Typography variant="h4">Payments</Typography>
          </Box>
        )}
        {selectedMenu === 'tasks' && <AddProduct />}
      </Box>

      {/* Діалогове вікно для створення нового користувача */}
      <Dialog open={openCreateUserModal} onClose={handleCloseCreateUserModal}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Username"
            value={newUser.username}
            onChange={(e) =>
              setNewUser({ ...newUser, username: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="dense"
            label="Password"
            type="password"
            value={newUser.password}
            onChange={(e) =>
              setNewUser({ ...newUser, password: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="dense"
            label="Phone Number"
            value={newUser.phoneNumber}
            onChange={(e) =>
              setNewUser({ ...newUser, phoneNumber: e.target.value })
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={newUser.isAdmin}
                onChange={(e) =>
                  setNewUser({ ...newUser, isAdmin: e.target.checked })
                }
              />
            }
            label="Is Admin"
          />
          <FormControlLabel
            control={
              <Switch
                checked={newUser.isFreeze}
                onChange={(e) =>
                  setNewUser({ ...newUser, isFreeze: e.target.checked })
                }
              />
            }
            label="Frozen"
          />
          <FormControlLabel
            control={
              <Switch
                checked={newUser.specialTask}
                onChange={(e) =>
                  setNewUser({ ...newUser, specialTask: e.target.checked })
                }
              />
            }
            label="Special Task"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateUserModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateUser} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {selectedUser && (
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              margin="dense"
              label="Name"
              value={selectedUser.username}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, username: e.target.value })
              }
            />
            <TextField
              fullWidth
              margin="dense"
              label="Phone"
              value={selectedUser.phoneNumber}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, phoneNumber: e.target.value })
              }
            />
            <TextField
              fullWidth
              margin="dense"
              label="Password"
              value={selectedUser.password}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, password: parseInt(e.target.value, 10) })
              }
            />
            <TextField
              fullWidth
              margin="dense"
              label="Balance"
              type="number"
              value={selectedUser.accountSummary}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, accountSummary: parseFloat(e.target.value) })
              }
            />
            <TextField
              fullWidth
              margin="dense"
              label="All The Time Profit"
              type="number"
              value={selectedUser.totalProfit}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, totalProfit: parseFloat(e.target.value) })
              }
            />
            <TextField
              fullWidth
              margin="dense"
              label="Level"
              type="number"
              value={selectedUser.level}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, level: parseInt(e.target.value, 10) })
              }
            />
            <TextField
              fullWidth
              margin="dense"
              label="Commission"
              type="number"
              value={selectedUser.commission}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, commission: parseFloat(e.target.value) })
              }
            />
            <TextField
              fullWidth
              margin="dense"
              label="Limit Task"
              type="number"
              value={selectedUser.limitTask || ''}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                setSelectedUser({ ...selectedUser, limitTask: isNaN(value) || value === 0 ? null : value });
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={selectedUser.isAdmin}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, isAdmin: e.target.checked })
                  }
                />
              }
              label="Is Admin"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={selectedUser.specialTask}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, specialTask: e.target.checked })
                  }
                />
              }
              label="Special Task"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={selectedUser.isFreeze}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, isFreeze: e.target.checked })
                  }
                />
              }
              label="Frozen"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={selectedUser.completedTasksToday === 0}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      completedTasksToday: e.target.checked ? 0 : selectedUser.completedTasksToday,
                      allTasksCompleted: e.target.checked ? false : selectedUser.allTasksCompleted
                    });
                  }}
                />
              }
              label="Reset Tasks Today to 0"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => handleDeleteUser(selectedUser._id)} style={{ marginLeft: '10px' }}>
              Delete
            </Button>
            <Button onClick={handleCloseModal} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSaveChanges} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default AdminPanelBasic;
