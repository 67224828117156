import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

// Конфігурація вашого Firebase проєкту
const firebaseConfig = {
  apiKey: "AIzaSyCh3TNkl5LfmBTRcQZ56t1GyGZjs1Y-VTQ",
  authDomain: "buytaskproject.firebaseapp.com",
  projectId: "buytaskproject",
  storageBucket: "buytaskproject.appspot.com",
  messagingSenderId: "972718812975",
  appId: "1:972718812975:web:e0622e747666255ae8fbda",
  measurementId: "G-CKEWF0JMG6"
};

// Ініціалізація Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Експорт налаштувань для використання в інших частинах додатку
export { auth, RecaptchaVerifier, signInWithPhoneNumber };
