import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Register.css';
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from './../../firebase'; 

const Register = ({ setRegistrationData }) => {
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumberState] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const passwordRequirements = [
      { regex: /.{8,}/, message: 'Password must be at least 8 characters long' },
      { regex: /[A-Z]/, message: 'Password must contain at least one uppercase letter' },
      { regex: /[a-z]/, message: 'Password must contain at least one lowercase letter' },
      { regex: /[0-9]/, message: 'Password must contain at least one number' },
      { regex: /[!@#$%^&*(),.?":{}|<>]/, message: 'Password must contain at least one special character' }
    ];

    for (const requirement of passwordRequirements) {
      if (!requirement.regex.test(password)) {
        return requirement.message;
      }
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    try {
      const formattedPhoneNumber = `+${phoneNumber.replace(/\D/g, '')}`;

      // Ініціалізація reCAPTCHA
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
      }, auth);

      // Відправлення OTP через Firebase
      const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, window.recaptchaVerifier);
      console.log('OTP sent successfully');

      setRegistrationData({ password, phoneNumber: formattedPhoneNumber, confirmationResult });
      navigate('/verify-otp');
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('Failed to send OTP. Please try again.');
    }
  };

  return (
    <div className="form-wrapper">
      <h2 className="title">Register</h2>
      <form onSubmit={handleSubmit}>
        <PhoneInput
          country={'us'}
          value={phoneNumber}
          onChange={setPhoneNumberState}
          containerClass="phone-input-container"
          inputClass="phone-input"
        />
        <input
          type="password"
          className="input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <p className="error-message">{error}</p>}
        <div id="recaptcha-container"></div> {/* Контейнер для reCAPTCHA */}
        <button type="submit" className="button">Send Verification Code</button>
      </form>
    </div>
  );
};

export default Register;
