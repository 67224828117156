import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, List, ListItem, ListItemText, Divider, Button } from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import './Tasks.css';
import FooterMenu from '../FooterMenu/FooterMenu';
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  textTransform: 'none',
  fontWeight: 'bold',
  color: '#007bff',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const TaskImage = styled('img')(({ theme }) => ({
  width: '80px',
  height: '80px',
  borderRadius: '8px',
  marginRight: theme.spacing(2),
  objectFit: 'cover',
}));

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/all-user-tasks`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        // Сортуємо завдання за датою у зворотному порядку
        const sortedTasks = response.data.sort((a, b) => {
          const dateA = new Date(a.completedAt || a.createdAt);
          const dateB = new Date(b.completedAt || b.createdAt);
          return dateB - dateA; // Новіші дати зверху
        });
  
        setTasks(sortedTasks);
      } catch (err) {
        console.error('Error fetching tasks:', err);
      }
    };
  
    fetchTasks();
  }, []);
  

  return (
    <Box className="tasks-container">
      <StyledButton onClick={() => navigate('/start-task')}>
        <AddIcon />
        <Typography variant="h6" component="span" style={{ marginLeft: '8px' }}>
          Get A Task
        </Typography>
      </StyledButton>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          Tasks history
        </Typography>
        <List>
          {tasks.map((task) => (
            <React.Fragment key={task._id}>
              <ListItem alignItems="flex-start">
                <TaskImage src={task.img} alt={task.title} />
                <ListItemText
                  primary={
                    <>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        {task.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          textTransform: 'capitalize',
                          color: task.status === 'Not Completed' ? 'red' : 'green',
                        }}
                      >
                        {task.status}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        Reward: ${task.reward.toFixed(2)}
                      </Typography>
                      <Typography variant="body2">
                        {new Date(task.completedAt || task.createdAt).toLocaleString()}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </StyledPaper>
      <FooterMenu />
    </Box>
  );
};

export default Tasks;
