import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, Modal } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import axios from 'axios';
import FooterMenu from '../FooterMenu/FooterMenu';
import NewTask from '../Task/NewTask';

const StartPage = () => {
  const [userData, setUserData] = useState({
    level: 0,
    dailyProfit: 0,
    completedTasksToday: 0,
    dailyLimit: 0, // початкове значення
    commission: 0, // Отримуємо комісію з БД користувача
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const [newTaskOpen, setNewTaskOpen] = useState(false);

  const fetchUserData = async () => {
    try {
      // Отримуємо дані про користувача
      const userResponse = await axios.get(`${apiUrl}/api/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const { level, completedTasksToday, dailyProfit, commission } = userResponse.data;

      // Отримуємо глобальні дані, які містять dailyLimit
      const dataResponse = await axios.get(`${apiUrl}/api/data`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const dailyLimit = dataResponse.data.dailyLimit[`level${level}`];

      setUserData({
        level,
        dailyProfit,
        completedTasksToday,
        dailyLimit,
        commission, // Встановлюємо комісію з БД
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchUserData, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const { level, dailyProfit, completedTasksToday, dailyLimit, commission } = userData;

  const handleOpen = () => setNewTaskOpen(true);
  const handleClose = () => {
    setNewTaskOpen(false);
    fetchUserData();
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f0f0f0',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: 600,
          width: '100%',
          padding: 2,
          background: 'linear-gradient(135deg, #64b5f6 30%, #2196f3 90%)',
          borderRadius: '10px',
          marginBottom: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Box display="flex" alignItems="center">
              <EmojiEventsIcon sx={{ color: 'yellow', marginRight: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                LV.{level}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
              Daily Orders: {dailyLimit}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
              Commission: {commission}%
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
              Daily Profit: ${dailyProfit.toFixed(2)}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
              Completed Or.: {completedTasksToday}/{dailyLimit}
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Button
        variant="contained"
        sx={{
          maxWidth: 600,
          width: '100%',
          padding: '10px 0',
          fontWeight: 'bold',
          marginBottom: 2,
          backgroundColor: '#FFD700',
          '&:hover': {
            backgroundColor: '#FFC107',
          },
          color: 'black'
        }}
        onClick={handleOpen}
      >
        GRAB ORDER
      </Button>

      <Modal
        open={newTaskOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'none'
        }}
      >
        <Box
          sx={{
            backgroundColor: 'transparent',
            borderRadius: '8px',
            boxShadow: 0,
            p: 0,
            outline: 'none',
            maxWidth: 600,
            width: '100%',
            height: 0,
            overflow: 'hidden',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <NewTask open={newTaskOpen} onClose={handleClose} />
        </Box>
      </Modal>
      <Paper
        elevation={2}
        sx={{
          maxWidth: 600,
          width: '100%',
          padding: 2,
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Order description
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          1. Each account can match orders per day.
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          2. The commission for placing an order is uniform and equals to {commission}% of the total order amount.
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          3. The system is based on LBS technology and automatically matches goods through cloud computing.
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          4. You cannot cancel the task. You must first complete any existing unfinished tasks before you can receive new ones.
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          5. Each account can match orders per day.
        </Typography>
      </Paper>
      <FooterMenu />
    </Box>
  );
};

export default StartPage;
