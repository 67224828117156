import React, { useEffect, useState } from 'react';
import './Withdrawal.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import FooterMenu from '../FooterMenu/FooterMenu';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Withdrawal = () => {
    const [accountSummary, setAccountSummary] = useState(0);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchAccountSummary = async () => {
          try {
            const response = await axios.get(`${apiUrl}/api/account-summary`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            });
            setAccountSummary(response.data.accountSummary);
          } catch (err) {
            console.error(err);
          }
        };
    
        fetchAccountSummary();
      }, []);

      const handleSelect = () => {
        navigate('/withdrawal-form');
      };

      return (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <Typography variant="h5" component="div" sx={{ mb: 2 }}>
            Channel Select
          </Typography>
          <Typography variant="h6" component="div" sx={{ mb: 1 }}>
            Balance: {accountSummary}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Select a useful channel
          </Typography>
          <Card button onClick={handleSelect} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, mx: 2, p: 1 }}>
            <CardActionArea sx={{ display: 'flex', alignItems: 'center' }}>
              <CardMedia
                component="img"
                sx={{ width: 50, height: 50, ml: 1 }}
                image="https://cryptologos.cc/logos/tether-usdt-logo.png" // Посилання на іконку USDT
                alt="USDT"
              />
              <CardContent>
                <Typography component="div" variant="h6">
                  USDT - TRC20
                </Typography>
              </CardContent>
            </CardActionArea>
            <ArrowForwardIosIcon sx={{ mr: 2 }} />
          </Card>
          <FooterMenu/>
        </Box>
      );
    };
    

export default Withdrawal;
