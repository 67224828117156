import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import './ChangePassword.css';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSendOtp = async () => {
    try {
      console.log('Sending OTP...');
      const response = await axios.post(`${apiUrl}/api/send-otp-password`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('OTP sent successfully', response);
      setStep(2);
    } catch (err) {
      console.error('Error sending OTP:', err);
      setErrorMessage('Error sending OTP. Please try again.');
    }
  };

  const handleChangePassword = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/verify-otp-password`, { otp, newPassword }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('Password changed successfully', response);
      alert('Password changed successfully');
      navigate('/profile');
    } catch (err) {
      console.error('Error verifying OTP or changing password:', err);
      setErrorMessage('Invalid OTP or error changing password. Please try again.');
    }
  };

  return (
    <Box p={2} maxWidth="500px" mx="auto" className="change-password-container">
      <Typography variant="h5" align="center">Change Password</Typography>
      {step === 1 && (
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSendOtp}>
            Send OTP
          </Button>
        </Box>
      )}
      {step === 2 && (
        <Box mt={2}>
          <TextField
            fullWidth
            label="OTP"
            variant="outlined"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="New Password"
            type="password"
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
          />
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleChangePassword}>
              Confirm
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChangePassword;
