import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Box, Grid } from '@mui/material';
import FooterMenu from '../FooterMenu/FooterMenu';
import axios from 'axios';

const WithdrawalForm = () => {
  const [amount, setAmount] = useState('');
  const [accountSummary, setAccountSummary] = useState(0);
  const [address, setAddress] = useState(localStorage.getItem('walletAddress') || 'TBGDKDBRDH7RvLgZesF111our4Zs2aHx7A');
  const [chatLink, setChatLink] = useState(''); // Зчитування з локального сховища або значення за замовчуванням
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchAccountSummary = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/account-summary`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setAccountSummary(response.data.accountSummary);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchChatLink = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/support-chat`);
        setChatLink(response.data.supportChat);
      } catch (error) {
        console.error('Error fetching support chat link:', error);
      }
    };

    fetchChatLink();
    fetchAccountSummary();
  }, []);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleAddressChange = () => {
    const newAddress = prompt('Enter new wallet address:', address);
    if (newAddress) {
      setAddress(newAddress);
      localStorage.setItem('walletAddress', newAddress); // Збереження в локальне сховище
    }
  };

  const handleConfirm = () => {
    if (parseFloat(amount) >= 40) {
      if (chatLink) {
      const whatsappLink = `${chatLink}&text=I%20want%20to%20withdraw%20${amount}%20USDT%20to%20address%20${address}`;
      window.location.href = whatsappLink;
      } else {
        alert('WhatsApp chat link is not available.');
      }
    } else {
      alert('Minimum withdrawal amount is 40 USDT');
    }
  };

  return (
    <Box className="withdrawal-container" p={3}>
      <Typography variant="h5" align="center" gutterBottom>Withdrawal</Typography>
      <Typography variant="h6" align="center" color="textSecondary" gutterBottom>USDT - TRC20</Typography>
      <Typography variant="body1" align="center" color="textSecondary" gutterBottom>Current Balance</Typography>
      <Typography variant="h6" align="center" gutterBottom>USDT {accountSummary}</Typography>
      <Box mt={3}>
        <Typography variant="body1" align="center" gutterBottom>Enter Withdrawal amount</Typography>
        <Grid container spacing={2} justifyContent="center">
          {['100', '200', '300', '500', '800', '1000', '2000', '4000'].map((amt) => (
            <Grid item key={amt}>
              <Button variant="outlined" onClick={() => setAmount(amt)}>
                USDT {amt}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Box mt={3}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Enter Amount"
            value={amount}
            onChange={handleAmountChange}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Typography variant="body1" gutterBottom>Wallet Address</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>USDT - TRC20 Wallet Address</Typography>
        <Typography variant="body1" style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px' }} gutterBottom>
          {address}
        </Typography>
        <Button color="primary" onClick={handleAddressChange} gutterBottom>
          Modify Wallet Address
        </Button>
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleConfirm} style={{ width: '100%' }}>
          Confirm
        </Button>
      </Box>
      <Box className="footer-space"></Box>
      <FooterMenu />
    </Box>
  );
};

export default WithdrawalForm;
