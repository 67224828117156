import { useEffect, useState, useCallback } from 'react';
import './App.css';
import Home from './Components/Home/Home';
import Login from './Components/LoginForm/Login';
import Register from './Components/RegisterForm/Register';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import VerifyOTP from './Components/VerifyOTP/VerifyOTP';
import ProtectedRoute from './Components/PrivateRoute/ProtectedRoute';
import Profile from './Components/Profile/Profile';
import Tasks from './Components/Task/Tasks';
import NewTask from './Components/Task/NewTask';
import Withdrawal from './Components/Withdrawal/Withdrawal';
import WithdrawalForm from './Components/Withdrawal/WithdrawalForm';
import EditProfile from './Components/Profile/EditProfile';
import ChangePassword from './Components/ChangePassword/ChangePassword';
import Recharge from './Components/Recharge/Recharge';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import AdminPanelBasic from './Components/AdminPanel/AdminPanelBasic';
import StartPage from './Components/Start/StartPage';
import AdminRoute from './Components/PrivateRoute/AdminRoute';
import axios from 'axios';

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [registrationData, setRegistrationData] = useState({});
  const navigate = useNavigate()

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
    },
  });

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.headers['x-logout']) {
          // Розлогування користувача, якщо заголовок X-Logout присутній
          setToken('');
          localStorage.removeItem('token');
          setUsername('');
          localStorage.removeItem('username');
          navigate('/login')
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

  return (
    <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={token ? <Navigate to="/home" /> : <Navigate to="/login" />} />
          <Route path="/register" element={<Register setRegistrationData={setRegistrationData} />} />
          <Route path="/verify-otp" element={
            <ProtectedRoute registrationData={registrationData}>
              <VerifyOTP registrationData={registrationData} />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<Login setToken={setToken} setUsername={setUsername} />} />
          <Route path="/home" element={
            <PrivateRoute token={token}>
              <Home />
            </PrivateRoute>
          } />
          <Route path="/profile" element={
            <PrivateRoute token={token}>
              <Profile />
            </PrivateRoute>
          } />
          <Route
            path="/tasks"
            element={
              <PrivateRoute token={token}>
                <Tasks />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-task"
            element={
              <PrivateRoute token={token}>
                <NewTask />
              </PrivateRoute>
            }
          />
          <Route
            path="/withdraw"
            element={
              <PrivateRoute token={token}>
                <Withdrawal />
              </PrivateRoute>
            }
          />
          <Route
            path="/withdrawal-form"
            element={
              <PrivateRoute token={token}>
                <WithdrawalForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <PrivateRoute token={token}>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute token={token}>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/recharge"
            element={
              <PrivateRoute token={token}>
                <Recharge />
              </PrivateRoute>
            }
          />
          <Route path="/admin" element={
            <AdminRoute token={token}>
              <AdminPanelBasic />
            </AdminRoute>
          } />
          <Route
            path='/start-task'
            element={
              <PrivateRoute token={token}>
                <StartPage />
              </PrivateRoute>
            }
          />
        </Routes>
    </ThemeProvider>
  );
};

export default App;
